@import '~antd/dist/antd.less';
@import 'variables-color';
@import 'font-sizes';

#root {
	height: 100%;
}

html, body {
	overflow-x:hidden
}


.ant-menu-item-selected {
	color: #1c1c1c !important;
	font-weight: 500 !important;
}
.ant-menu-item-active {
	color: #1c1c1c !important;
	font-weight: 500 !important;
}

.ant-menu-item {
	&:hover {
		color: #000 !important;
	}
}

// ant-picker-circle-style

.ant-picker-cell-inner {
	border-radius: 50% !important;
}

.ant-picker-cell-inner:before {
	border-radius: 50% !important;
}

.example {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform: -webkit-translate(-50%, -50%);
	transform: -moz-translate(-50%, -50%);
	transform: -ms-translate(-50%, -50%);
}

@primary-color: @brand-color;@card-padding-base: 10px;@card-radius: 2px;@card-head-padding: 24px;@layout-header-background: #FFFFFF;@page-header-back-color: #FFFFFF;@page-header-ghost-bg: #FFFFFF;@menu-item-active-border-width: none;@typography-title-margin-top: 0px;@typography-title-margin-bottom: 0px;@typography-title-font-weight: 500;@menu-dark-selected-item-text-color: #FFFFFF;@menu-item-active-bg: #FFEFB0;