.ant-upload.ant-upload-select-picture-card {
    background-color: inherit;
    border: inherit;
    height: unset;
    width: unset;
    display: unset;
}
.ant-upload.ant-upload-select-picture-card>.ant-upload {
    justify-content: unset;
}


