.ant-upload.ant-upload-select-picture-card {
    width: 280px;
    height: 225px;
}

.ant-upload-list-picture-card-container {
    width: 280px;
    height: 225px;
    /* margin: 0 0px 0px 0; */
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
    display: inline-table !important;
}

.ant-upload-list-picture-card .ant-upload-list-item {
    padding: inherit;
    border: none;
    width: 280px;
    height: 225px;
}

.avatar-uploader:hover {
    visibility: initial;
}

.anticon-delete {
    display: none !important;
    /* visibility: hidden !important; */
}

.anticon-eye {
    margin-left: 35px !important;
}

.ant-modal-close {
    margin: -13px !important;
}

.uploadButton {
    width: 280px !important;
    height: 225px !important;
}

@media only screen and (min-width: 100px) and (max-width:600px) {
    .ant-upload-list-picture-card .ant-upload-list-item {
        width: fit-content !important;
        height: fit-content !important;
    }

    .ant-upload-list-picture-card-container {
        width: fit-content !important;
        height: fit-content !important;
    }

    .uploadButton {
        width: fit-content !important;
    }
}
