.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
    display: flex;
    align-items: center;
}

.row-dragging td{
    padding: 35px;
}
